import React, { useEffect, useState } from 'react';
import styles from '../organisms/RatingForm.module.scss'
import { FontIcon } from 'office-ui-fabric-react';
import { IRatingAnsweredOption } from '../../store/business/interfaces';


export interface RatingAnswerProps {
    answerId: string;
    answeredOption: IRatingAnsweredOption;
    questionText: string;
    maxRating: number;
    disabled: boolean;
    currentBallot: number;
    updateAnswers: (answerId: string, rating: number) => void;
}

/**
 * sets updates value of rated stars
 * @param props : {
    questionId: string;
    answeredOption: IRatingAnsweredOption;
    questionText: string;
    starAmount: number;
    disabled: boolean;
    updateAnswers: (answerId: string, rating: number) => void; }
 * @returns answer text and rating stars
 */
export const RatingAnswer = (props: RatingAnswerProps) => {

    const [rating, setRating] = useState<number>(0);
    const [hover, setHover] = useState(0);

    useEffect(() => {
        setHover(0);
        props.answeredOption ? setRating(props.answeredOption.rating) : setRating(0);
    }, [props.currentBallot, props.answeredOption])

    return (
        <>
            <div className={styles.ratingAnswerContainer}>
                <div className={styles.ratingAnswerTextDiv}>
                    <span className={styles.questionText} data-testid="questionText">{props.questionText}</span>
                </div>
                <div className={styles.ratingAnswerStarsContainer}>
                    {
                        [...Array(props.maxRating)].map((star, index) => {
                            index += 1;
                            return (
                                <button
                                    type="button"
                                    key={index}
                                    className={styles.star}
                                    onClick={
                                        () => {
                                            setRating(index);
                                            props.updateAnswers(props.answerId, index)
                                        }
                                    }
                                    // onMouseEnter: star filling is displayed according to index, onMouseLeave: star filling returns to actual rating-value, unless new rating value is set onClick
                                    onMouseEnter={() => setHover(index)}
                                    onMouseLeave={() => setHover(rating)}
                                    onTouchStart={() => setHover(index)}
                                    onTouchMove={() => setHover(index)}
                                    onTouchEnd={() => setHover(rating)}
                                    disabled={props.disabled}
                                    data-testid='starButton'
                                >
                                    {
                                        (index <= rating || index <= hover) ?
                                        <FontIcon iconName='FavoriteStarFill' data-testid={`fullStar${index}`}/> : 
                                        <FontIcon iconName='FavoriteStar' data-testid={`emptyStar${index}`}/> 
                                    }
                                </button>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}