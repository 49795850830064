import { BusinessDataType } from './enums';
import { ISurvey, IQuestion, IQuestionResult, IDesign } from './interfaces';

export const DATA_LOADING = 'DATA_LOADING';
export const SURVEY_LOADED = 'SURVEY_LOADED';
export const QUESTIONS_LOADED = 'QUESTIONS_LOADED';
export const RESULT_LOADED = 'RESULT_LOADED';
export const MEMBER_LOADED = 'MEMBER_LOADED';
export const DESIGN_LOADED = 'DESIGN_LOADED';
export const TENANT_SET = 'TENANT_SET';

// Action Definition
interface IDataLoadingAction {
	type: typeof DATA_LOADING;
	loading: BusinessDataType;
	isLoading: boolean;
}

interface ISurveyLoadedAction {
	type: typeof SURVEY_LOADED;
	survey?: ISurvey;
}

interface IQuestionsLoadedAction {
	type: typeof QUESTIONS_LOADED;
	questions?: IQuestion[];
}

interface IResultLoadedAction {
	type: typeof RESULT_LOADED;
	questionResult?: IQuestionResult;
}

interface IMemberLoadedAction {
	type: typeof MEMBER_LOADED;
	tenantId?: string;
	memberLogin?: string;
	surveyCode?: string;
	connection?: string;
}

interface IDesignLoadedAction {
	type: typeof DESIGN_LOADED;
	design?: IDesign;
}

interface ITenantSetAction {
	type: typeof TENANT_SET;
	tenantId?: string;
}

// Union Action Types
export type IBusinessActionTypes =
	| IDataLoadingAction
	| ISurveyLoadedAction
	| IQuestionsLoadedAction
	| IResultLoadedAction
	| IMemberLoadedAction
	| IDesignLoadedAction
	| ITenantSetAction
;
