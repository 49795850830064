import React from 'react';
import { PrimaryButton, IButtonProps } from 'office-ui-fabric-react';
import { useTranslation } from 'react-i18next';
import { SystemLoading } from '../../store/system/enums';
import { useLoading } from '../../shared/hooks';

export interface Props extends IButtonProps {
	loadingClassName?: string;
	exclude?: SystemLoading[];
	disabled: boolean;
}

export const LoadingSpinnerButton = (props: Props) => {
	const loading = useLoading(props.exclude);
	const { t } = useTranslation();
	return (
		<>{loading ? <div className={props.loadingClassName}>{t('loading.wait')}</div> : <PrimaryButton {...props} />}</>
	);
};
