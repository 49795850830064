import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontIcon } from 'office-ui-fabric-react';
import styles from './PersonBlockHeader.module.scss';

interface Props {
	label: string;
	withLegend: boolean;
}

export const PersonBlockHeader = (props: Props) => {
	const { t } = useTranslation();

	return (
		<div className={styles.personLegendContainer}>
			<span className={styles.personLabel}>{props.label}</span>
			{props.withLegend && (
				<>
					<div>
						<FontIcon iconName='CheckMark' />
						<span>{t('form.person.yes')}</span>
					</div>
					<div>
						<FontIcon iconName='Cancel' />
						<span>{t('form.person.no')}</span>
					</div>
					<div>
						<FontIcon iconName='Blocked' />
						<span>{t('form.person.abstent')}</span>
					</div>
				</>
			)}
		</div>
	);
};
