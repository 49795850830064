import { IBusinessActionTypes, DATA_LOADING, SURVEY_LOADED, QUESTIONS_LOADED, MEMBER_LOADED, RESULT_LOADED, TENANT_SET, DESIGN_LOADED } from './types';

import { BusinessDataType } from './enums';
import { ISurvey, IQuestion, IQuestionResult, IDesign } from './interfaces';

export const businessLoading = (loading: BusinessDataType, isLoading: boolean): IBusinessActionTypes => {
	return {
		type: DATA_LOADING,
		loading,
		isLoading,
	};
};

export const surveyLoaded = (survey: ISurvey): IBusinessActionTypes => {
	return {
		type: SURVEY_LOADED,
		survey,
	};
};

export const questionsLoaded = (questions?: IQuestion[]): IBusinessActionTypes => {
	return {
		type: QUESTIONS_LOADED,
		questions,
	};
};

export const questionResultLoaded = (questionResult?: IQuestionResult): IBusinessActionTypes => {
	return {
		type: RESULT_LOADED,
		questionResult,
	};
};

export const memberLoaded = (tenantId?: string, memberLogin?: string, surveyCode?: string, connection?: string): IBusinessActionTypes => {
	return {
		type: MEMBER_LOADED,
		tenantId,
		memberLogin,
		surveyCode,
		connection 
	};
};

export const designLoaded = (design : IDesign): IBusinessActionTypes => {
	return {
		type: DESIGN_LOADED,
		design
	};
}

export const tenantSet = (tenantId?: string): IBusinessActionTypes => {
	return {
		type: TENANT_SET,
		tenantId
	};
}