import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Route, Switch, useLocation, useHistory, useParams } from 'react-router-dom';
import { LoginCode } from './LoginCode';
import { VotingService } from '../services/VotingService';
import { useSelector } from 'react-redux';
import { AppState, store } from '../store';
import { Voting } from './Voting';
import { SignalR } from '../shared/SignalRContext';
import styles from './Hub.module.scss';
import logo from '../images/logo.png';
import { FontIcon } from 'office-ui-fabric-react';
import { Menu } from '../components/Menu';
import { SystemService } from '../services/SystemService';
import { useTranslation } from 'react-i18next';
import { Logo } from '../shared/components/atoms/Logo';
import { showMessage, MessageType } from '../shared/notifications';
import { SidePanel } from '../components/molecules/SidePanel';
import { DesignService } from '../services/DesignService';
import { MemberLogo } from '../components/atoms/MemberLogo';
import { designLoaded, tenantSet } from '../store/business/actions';

export const Hub = () => {
	const title = useSelector((state: AppState) => state.system.title);
	const apiBasePath = useSelector((state: AppState) => state.system.apiBasePath);
	const userId = useSelector((state: AppState) => state.system.userId);
	const tenantId = useSelector((state: AppState) => state.business.tenantId);
	const survey = useSelector((state: AppState) => state.business.survey);
	const design = useSelector((state: AppState) => state.business.design);
	const [localSurveyCode, setLocalSurveyCode] = useState('');
	const [openMenu, setOpenMenu] = useState(false);
	const history = useHistory();
	const location = useLocation();
	const [showSurveyCodeError, SetShowSurveyCodeError] = useState(false);
	const [showLoginError, SetShowLoginError] = useState(false);
	const transitionNodeRef = useRef<HTMLDivElement>(null);
	const { t } = useTranslation();

	const validateSurveyCode = useCallback(
		async (surveyCode: string) => {
			VotingService.login(surveyCode)
				.then(async () => {
					setLocalSurveyCode(surveyCode);
					const design = await DesignService.getDesign(surveyCode);
					store.dispatch(designLoaded(design));
					history.push('/login');
				})
				.catch(() => {
					SetShowSurveyCodeError(true);
				});
			SystemService.ensureConfig(true);
		},
		[history]
	);

	const validateMemberLogin = useCallback(
		(surveyCode: string, memberLogin: string) => {
			VotingService.login(surveyCode!, memberLogin)
				.then(async () => {
					SystemService.ensureConfig(true);
					history.push('/voting');
				})
				.catch(() => {
					SetShowLoginError(true);
				});
		},
		[history]
	);

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const error = params.get('error');
		if (error) {
			showMessage(`messages.${error}`, MessageType.ERROR, true);
			history.replace('/');
		}
		const qSurveyCode = params.get('survey');
		const qPin = params.get('pin');

		//try load survey code from Query String

		if (qPin && qSurveyCode) {
			if (userId) {
				VotingService.logout().then(() => {
					window.location.href = `/?survey=${qSurveyCode}&pin=${qPin}`;
				});
			} else {
				validateMemberLogin(qSurveyCode, qPin);
			}
		} else if (qSurveyCode) {
			if (userId) {
				VotingService.logout().then(() => {
					window.location.href = `/?survey=${qSurveyCode}`;
				});
			} else {
				validateSurveyCode(qSurveyCode);
			}
		} else if (userId) {
			history.push('/voting');
		}
	}, [tenantId, userId, history, location.search, validateSurveyCode, validateMemberLogin]);

	useEffect(() => {
		if (
			(location.pathname === '/voting' && !userId) ||
			(location.pathname === '/login' && !localSurveyCode && !userId)
		) {
			history.push('/');
		}
	}, [history, location.pathname, userId, localSurveyCode]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		document.title = survey?.title || title || 'VOTR';
	}, [survey, title]);

	const VotingComponent = () => {
		if (apiBasePath && userId) {
			VotingService.ensureSurvey();
			if (survey) {
				return (
					<div key='votingPage' ref={transitionNodeRef}>
						<div className={styles.headerContainer}>
							<div className={styles.headerInnerContainer}>
								<h1>{survey?.title || title}</h1>
								<div
									className={styles.menuButton}
									onClick={() => {
										setOpenMenu(true);
									}}
								>
									<FontIcon iconName='BulletedListText' />
								</div>
							</div>
						</div>
						<SignalR url={apiBasePath} userId={userId}>
							<Voting survey={survey} />
						</SignalR>
					</div>
				);
			}
		}
		return '';
	};

	const logout = () => {
		VotingService.logout().then(() => {
			window.location.href = `/${tenantId ? tenantId : ''}`;
		});
	};

	const LoginPinComponent = () => {
		return (
			<div key='loginPage' className={styles.loginPage} ref={transitionNodeRef}>
				<div className={styles.headerContainer}>
					<div className={styles.headerInnerContainer}>
						<h1>{survey?.title || title}</h1>
						<div
							className={styles.menuButton}
							onClick={() => {
								setOpenMenu(true);
							}}
						>
							<FontIcon iconName='BulletedListText' />
						</div>
					</div>
				</div>
				<div className={styles.contentContainer}>
					<div className={[styles.logoWrapperDiv, design?.logo ? '' : styles.noLogo].join(' ')}>
						<MemberLogo src={design?.logo} />
					</div>
					<LoginCode
						key='login'
						label={survey?.useQuickVotes ? t('login.name.label') : t('login.pin.label')}
						maxLength={survey?.useQuickVotes ? 35 : 6}
						placeholder={survey?.useQuickVotes ? '' : t('login.pin.placeholder')}
						onNext={(value) => {
							validateMemberLogin(localSurveyCode, value);
						}}
						type={survey?.useQuickVotes ? 'name' : 'pin'}
						validationErrorVisible={showLoginError}
						validationErrorMessage={survey?.useQuickVotes ? t('login.name.errorMsg') : t('login.pin.errorMsg')}
					/>
				</div>
			</div>
		);
	};

	const LoginSurveyComponent = () => {
		let { pathTenantId } = useParams<any>();
		if (pathTenantId === undefined) {
			(async () => {
				await SystemService.ensureConfig(true);
			})();
		} else {
			store.dispatch(tenantSet(pathTenantId));
			history.push('/');
		}
		return (
			<div key='startPage' className={styles.startpage} ref={transitionNodeRef}>
				<div className={styles.headerContainer}>
					<img alt={t('votr')} src={logo} className={styles.votrLogo} />
				</div>
				<div className={styles.contentContainer}>
					<LoginCode
						key='code'
						label={t('login.code.label')}
						placeholder={t('login.code.placeholder')}
						maxLength={6}
						onNext={(value) => {
							validateSurveyCode(value);
						}}
						type='surveyCode'
						validationErrorVisible={showSurveyCodeError}
						validationErrorMessage={t('login.code.errorMsg')}
					/>
				</div>
				<div className={styles.contentExitContainer}></div>
			</div>
		);
	};

	return (
		<div className={styles.pageContainer}>
			<Switch key='switch' location={location}>
				<Route key='routeLogin' path='/login' exact={true}>
					{LoginPinComponent()}
				</Route>
				<Route key='routeVoting' path='/voting' exact={true}>
					{VotingComponent()}
				</Route>
				<Route key='routeRoot' path='/:pathTenantId' exact={false} component={LoginSurveyComponent} />
				<Route key='routeRoot' path='/' exact={false} component={LoginSurveyComponent} />
			</Switch>
			<SidePanel
				title={title}
				visible={openMenu}
				renderMenu={() => {
					return (
						<>
							<div className={styles.contentContainer}>
								<Menu
									isSignedIn={!!userId}
									onToggleMenu={() => {
										setOpenMenu(false);
										logout();
									}}
								/>
							</div>
							<div className={styles.menuLogo}>
								<Logo size='big' />
							</div>
						</>
					);
				}}
				onClose={() => setOpenMenu(false)}
				logoSrc={design?.logo}
			/>
			<div className={styles.logo}>
				<Logo />
			</div>
		</div>
	);
};
