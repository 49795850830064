import React, { useContext, useEffect, useState } from 'react';
import styles from './Voting.module.scss';
import { IQuestion, ISurvey } from '../store/business/interfaces';
import { SignalRContext, ISignalRConnection } from '../shared/SignalRContext';
import { QuestionService } from '../services/QuestionService';
import { useSelector } from 'react-redux';
import { AppState } from '../store';
import { VotingForm } from '../components/organisms/VotingForm';
import { useTabIsVisible } from '../hooks';
import { anyInArray } from '../shared/utils';
import { VotingService } from '../services/VotingService';
import { showMessage, clearMessages, MessageType } from '../shared/notifications';
import { useTranslation } from 'react-i18next';
import { ResultGraph } from '../components/molecules/ResultGraph';
import { MultipleQuestionsToolbar } from '../components/molecules/MultipleQuestionsToolbar';
import { ResultPersonGraph } from '../shared/components/molecules/ResultPersonGraph';
import { VotingHeader } from '../components/molecules/VotingHeader';
import { ResultGraphRating } from '../components/molecules/ResultGraphRating';

export interface VotingProps {
	survey: ISurvey;
}
/**
 * manages SignalR connection (login status);
 * loads all currently active Questions;
 * calls QuestionService.reloadAllQuestions();
 * @param props :  {survey: ISurvey;}
 * @returns MultipleQuestionsToolbar, <VotingHeader question={selectedQuestion || questionResult} />, VotingForm of selected Question or results of Question after vote is finished
 */
export const Voting = (props: VotingProps) => {
	const connection = useContext<ISignalRConnection>(SignalRContext);
	const tenantId = useSelector((state: AppState) => state.business.tenantId);
	const questions = useSelector((state: AppState) => state.business.questions);
	const questionResult = useSelector((state: AppState) => state.business.questionResult);
	const [selectedQuestion, setSelectedQuestion] = useState<IQuestion>(questions ? questions[0] : undefined);
	const connectionId = useSelector((state: AppState) => state.business.connection);
	const { t } = useTranslation();

	if (!(questions || questionResult)) {
		QuestionService.reloadAllQuestions(false);
	}

	useEffect(() => {
		connection.on('member', (args) => {
			console.log('signalR', args);
			if (args.type === 'survey') {
				if (args.action === 'delete' || args.action === 'archive') {
					VotingService.logout().then(() => {
						window.location.href = '/?error=surveyErased';
					});
				} else {
					VotingService.ensureSurvey(true);
				}
			} else if (args.type === 'member') {
				if (args.action === 'delete') {
					VotingService.logout().then(() => {
						window.location.href = `/?error=autoLogout`;
					});
				}
			} else if (args.type === 'connection') {
				if (args.action === 'login') {
					const loginConnectionId = args.connectionId;
					if (loginConnectionId !== connectionId) {
						VotingService.logout().then(() => {
							window.location.href = `/?error=singleLogout`;
						});
					}
				}
			} else if (args.type === 'question') {
				QuestionService.reloadAllQuestions(true);
			}
		});
		connection.onReconnected(() => {
			QuestionService.reloadAllQuestions(true);
		});
		return () => {
			connection.off('member');
		};
	}, [connection, connectionId]);

	useEffect(() => {
		clearMessages();
		if (selectedQuestion?.hasAnswered) {
			showMessage('messages.answerSuccess', MessageType.SUCCESS, true);
		}
	}, [selectedQuestion]);

	useEffect(() => {
		let currentQuestion = questions?.find((q) => q.id === selectedQuestion?.id);
		if (!currentQuestion) currentQuestion = questions ? questions[0] : undefined;
		setSelectedQuestion(currentQuestion);
	}, [questions]);

	useTabIsVisible((visible) => {
		if (visible) {
			QuestionService.reloadAllQuestions(true);
		}
	});

	useEffect(() => {
		QuestionService.reloadAllQuestions(false);
	}, [tenantId]);

	return (
		<div className={styles.votingContainer}>
			<div className={styles.votingContentContainer}>
				<MultipleQuestionsToolbar
					questions={questions}
					selectedQuestion={selectedQuestion}
					onSelectionChanged={(newSelectedQuestion) => {
						setSelectedQuestion(questions?.find((q) => q.id === newSelectedQuestion.id));
					}}
				/>
				<VotingHeader question={selectedQuestion || (!props.survey.disableResultsInMemberView ? questionResult : null)} />
				{selectedQuestion && (
					<>
						<VotingForm
							survey={props.survey}
							question={selectedQuestion}
							onSend={(answerOptions, comment) => {
								if (
									selectedQuestion.kind === 'choice' &&
									!anyInArray(answerOptions) &&
									selectedQuestion.votesPerMember === 1
								) {
									showMessage('messages.chooseOneAnswer', MessageType.WARN);
								} else if (
									selectedQuestion.kind === 'choice' &&
									!anyInArray(answerOptions) &&
									selectedQuestion.votesPerMember > 1
								) {
									showMessage('messages.chooseOneAnswerMin', MessageType.WARN);
								} else if (selectedQuestion.kind === 'text' && !comment) {
									showMessage('messages.enterComment', MessageType.WARN);
								} else {
									QuestionService.answerQuestion(selectedQuestion, answerOptions, comment);
								}
							}}
							onSendBallotAnswers={(answeredOptions) => {
								QuestionService.answerBallotQuestion(selectedQuestion, answeredOptions);
							}}
							onUnVote={() => {
								QuestionService.unAnswer(selectedQuestion);
							}}
							marginContainerName={styles.marginContainer}
						/>
					</>
				)}
				{questionResult && !props.survey.disableResultsInMemberView && (
					<>
						<div className={styles.votingResultContainer}>
							<div className={styles.marginContainerInner}>
								<div className={styles.resultHeader}>{t('results.header')}</div>
								{questionResult.kind === 'choice' && <ResultGraph question={questionResult}></ResultGraph>}

								{questionResult.kind === 'text' && (
									<div className={styles.textResultContainer}>
										{questionResult.comments.sort().map((comment) => {
											return (
												<div className={styles.textResultOuter}>
													<div className={styles.textResult}>
														<div className={styles.textResultInner}>{comment}</div>
													</div>
												</div>
											);
										})}
									</div>
								)}
								{questionResult.kind === 'person' && (
									<ResultPersonGraph
										answerOptions={questionResult.answerOptions}
										useVoteWeightInPercent={props.survey.useVoteWeightInPercent}
										sortResultsByVotes={props.survey.sortResultsByVotes}
									></ResultPersonGraph>
								)}
								{questionResult.kind === 'rating' && (
									<ResultGraphRating questionResult={questionResult} question={selectedQuestion} />
								)}
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
};
