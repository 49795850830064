import { ISystemActionTypes, SYSTEM_LOADING, MESSAGE_ADD, MESSAGE_REMOVE, MESSAGES_CLEAR, APPCONFIG_LOADED, CONFIG_LOADED } from './types';
import { SystemLoading } from './enums';
import { MessageType } from '../../shared/notifications';

export const systemLoading = (loading: SystemLoading, isLoading: boolean): ISystemActionTypes => {
	return {
		type: SYSTEM_LOADING,
		loading,
		isLoading,
	};
};

export const messageAdd = (type: MessageType, message: string | undefined, sticky: boolean): ISystemActionTypes => {
	return {
		type: MESSAGE_ADD,
		created: new Date(),
		messageType: type,
		message,
		sticky,
	};
};

export const messageRemove = (index: number): ISystemActionTypes => {
	return {
		type: MESSAGE_REMOVE,
		index,
	};
};

export const messagesClear = (): ISystemActionTypes => {
	return {
		type: MESSAGES_CLEAR,
	};
};

export const appConfigLoaded = (
	apiBasePath: string,
	version: number,
): ISystemActionTypes => {
	return {
		type: APPCONFIG_LOADED,
		apiBasePath,
		version,
	};
};

export const configLoaded = (
	title: string | null,
	userId: string | null,
	apiKey: string | null,
): ISystemActionTypes => {
	return {
		type: CONFIG_LOADED,
		title,
		userId,
		apiKey,
	};
};
