import React, { useEffect, useState } from 'react';
import { FontIcon } from 'office-ui-fabric-react';

import { IAnswerOptionResult, IQuestion } from '../../store/business/interfaces';

import styles from './ResultRatingGeneral.module.scss';

export interface IResultRatingGeneralProps {
	answerOption: IAnswerOptionResult;
	index: number;
	maxRating: number;
	question: IQuestion;
	averageRating: number;
}

/**
 * calculates percentage from average rating result and displays a range of filled stars according to result. Rounds average Rating to 1 decimal
 * @param props IResultRatingGeneralProps {
    answerOption: IAnswerOptionResult; 
    index: number;
    maxRating: number;
    question: IQuestion;
    averageRating: number,
}
 * @returns 
 */
export const ResultRatingGeneral = (props: IResultRatingGeneralProps) => {
	const [result, setResult] = useState(0);
	const [starFilling, setStarFilling] = useState(0);

	useEffect(() => {
		setResult(props.averageRating);
		calcPercent();
	}, [props.answerOption]);

    useEffect(() => {
        setResult(props.averageRating);
        calcPercent();
    }, [props.answerOption])

    const calcPercent = () => {
		let percent = (Number(props.averageRating.toFixed(1)) / props.maxRating) * 100;
		setStarFilling(percent);
	};

	return (
		<>
			{
				<>
					<h3 className={styles.answerTitle}>{props.answerOption.title}</h3>
					<div className={styles.resultAnswerContainer}>
						<div className={styles.ratingEmpty}>
							{[...Array(props.maxRating)].map((star, index) => {
								return <FontIcon key={index} iconName='FavoriteStar' className={styles.star} />;
							})}
							<div className={styles.ratingFill} style={{ width: `${starFilling}%` }}>
								{[...Array(props.maxRating)].map((star, index) => {
									return <FontIcon key={index} iconName='FavoriteStarFill' className={styles.star} />;
								})}
							</div>
						</div>
						<span>{result.toFixed(1)}</span>
					</div>
				</>
			}
		</>
	);
};
