import React from 'react';
import { FontIcon } from 'office-ui-fabric-react';
import { useTranslation } from 'react-i18next';
import { IQuestion } from '../../store/business/interfaces';
import styles from './PersonHintRow.module.scss';

interface Props {
	question: IQuestion;
	remainingYes: number;
}
export const PersonHintRow = (props: Props) => {
	const { t } = useTranslation();

	return (
		<>
			{props.question.votesPerMember < props.question.answerOptions.length && (
				<div className={styles.personHintRow}>
					<FontIcon className={styles.icon} iconName={'Info'} />
					<span>
						{props.remainingYes
							? props.remainingYes === 1
								? t(`form.personVoteHint1Yes`)
								: t(`form.personVoteHintYes`, { remainingYes: `${props.remainingYes}` })
							: t(`form.personVoteHintExhaustedYes`)}
					</span>
				</div>
			)}
		</>
	);
};
