import { store } from '../store';
import { memberLoaded } from '../store/business/actions';
import cookie from 'js-cookie';

interface SessionModel {
	tenantId: string;
	memberLogin: string;
	surveyCode: string;
	connection: string;
}

export class SessionService {
	public static async createSession(tenantId: string, memberLogin: string, surveyCode: string, connection: string) {
		//console.debug(`Create Session: ${tenantId}, ${memberLogin}, ${surveyCode}, ${connection}`);
		cookie.set(
			'votr',
			{ tenantId, memberLogin, surveyCode, connection },
			{ expires: 1, secure: true, sameSite: 'Strict' }
		);
	}

	private static sessionPromise: Promise<void>;
	public static async ensureSession() {
		if (!SessionService.sessionPromise) {
			SessionService.sessionPromise = new Promise(async (resolve) => {
				const { business } = store.getState();
				if (!business.memberLogin || !business.surveyCode || !business.connection || !business.tenantId) {
					const session = await this.getSession();
					if (session.memberLogin && session.surveyCode && session.connection) {
						//console.debug(`Session: ${JSON.stringify(session)}`);
						store.dispatch(memberLoaded(session.tenantId, session.memberLogin, session.surveyCode, session.connection));
					} else if (session.tenantId) {
						store.dispatch(memberLoaded(session.tenantId, '', '', ''));
					}

					resolve();
				}
			});
		}
		return SessionService.sessionPromise;
	}

	private static async getSession(): Promise<SessionModel> {
		const model = cookie.getJSON('votr') as SessionModel;
		return model ?? { tenantId: '', surveyCode: '', memberLogin: '', connection: '' };
	}

	public static async deleteSession() {
		SessionService.sessionPromise = undefined;
		store.dispatch(memberLoaded(undefined, undefined, undefined, undefined));
		cookie.remove('votr');
	}
}
