import { MessageType } from '../../shared/notifications';
import { SystemLoading } from './enums';

export const SYSTEM_LOADING = 'SYSTEM_LOADING';
export const APPCONFIG_LOADED = 'APPCONFIG_LOADED';
export const CONFIG_LOADED = 'CONFIG_LOADED';
export const CONFIG_SET = 'CONFIG_SET';
export const MESSAGE_ADD = 'MESSAGE_ADD';
export const MESSAGE_REMOVE = 'MESSAGE_REMOVE';
export const MESSAGES_CLEAR = 'MESSAGE_CLEAR';

// Action Definition
interface ISystemLoadingAction {
	type: typeof SYSTEM_LOADING;
	loading: SystemLoading;
	isLoading: boolean;
}

interface IMessageAddAction {
	type: typeof MESSAGE_ADD;
	created: Date;
	messageType: MessageType;
	message?: string;
	sticky?: boolean;
}

interface IMessageRemoveAction {
	type: typeof MESSAGE_REMOVE;
	index: number;
}

interface IMessagesClearAction {
	type: typeof MESSAGES_CLEAR;
}

interface IAppConfigLoadedAction {
	type: typeof APPCONFIG_LOADED;
	apiBasePath?: string;
	version: number;
}

interface IConfigLoadedAction {
	type: typeof CONFIG_LOADED;
	title: string | null;
	userId: string | null;
	apiBasePath?: string;
	apiKey: string | null;
}

// Union Action Types
export type ISystemActionTypes = ISystemLoadingAction | IMessageAddAction | IMessageRemoveAction | IMessagesClearAction | IConfigLoadedAction | IAppConfigLoadedAction;