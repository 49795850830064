import { xhr } from '../xhr';
import { IDesign } from '../store/business/interfaces';
import { store } from '../store';
import { designLoaded } from '../store/business/actions';

export class DesignService {
	private static parseDesign(data: any): IDesign {
		return {
			surveyId: data.surveyId,
			logo: data.logo,
		};
	}

	public static async getDesign(surveyCode: string): Promise<IDesign> {
		const serviceResponse = await xhr(`Voting/Design/${surveyCode}`, {
			method: 'GET',
			onError: (response, status) => {
				return null;
			},
		});
		let loadedDesign: IDesign = serviceResponse ? DesignService.parseDesign(serviceResponse.data) : null;
		store.dispatch(designLoaded(loadedDesign));
		return loadedDesign;
	}
}
