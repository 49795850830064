import { IQuestion, IRatingAnsweredOption, IAnsweredOption } from '../../store/business/interfaces';
import { QuestionService } from '../QuestionService';
import { IManager } from './IManager';


/**
 * maps question details of question kind "rating" as IRatingAnsweredOption
 */
class RatingManager implements IManager {

	/**
	 * responsible, if question.kind === "rating"
	 * @param question IQuestion
	 * @returns boolean
	 */
	areYouResponsible(question: IQuestion) {
		return question.kind === "rating";
	};

	/**
	 * maps data IAnsweredOption into IRatingAnswerOption as preparation for POST-request
	 * @param answeredOptions : IAnsweredOption[]
	 * @returns answerOptions : IRatingAnsweredOption
	 */
	prepareAnsweredOptions(answeredOptions: IAnsweredOption[]) {
		const result = (answeredOptions as IRatingAnsweredOption[])?.map((ao) => {
			return {
				id: ao.id,
				rating: ao.rating,
				voteWeight: ao.voteWeight ?? undefined,
				ballotNumber: ao.ballotNumber ?? undefined,
			};
		});
		return result;
	}
}

QuestionService.addManager(new RatingManager());
