import { FontIcon } from 'office-ui-fabric-react';
import React from 'react';
import { AnswerChoice, IPersonChoice } from '../../store/business/interfaces';
import styles from '../organisms/PersonForm.module.scss';

export interface Props<T> {
	answeredOption: T | undefined;
	answeredChoiceType: AnswerChoice;
	disabled: boolean;
	updateAnswers: (chosenAnswer: T) => void;
}

const classNames = {
	[AnswerChoice.yes]: styles.personAnswerYes,
	[AnswerChoice.no]: styles.personAnswerNo,
	[AnswerChoice.abstent]: styles.personAnswerAbstent,
};

export const PersonChoiceIcon = <T extends IPersonChoice>(props: Props<T>) => {
	const isSelected = props.answeredOption?.answerChoice === props.answeredChoiceType;

	return (
		<>
			<div
				data-testid={`person-choice-icon`}
				className={[
					styles.personAnswerCheckIconDiv,
					styles.button,
					isSelected ? styles.personAnswerSelected : '',
					classNames[props.answeredChoiceType],
					props.disabled ? styles.disabled : '',
				].join(' ')}
				onClick={() => {
					if (props.disabled || isSelected) return;
					props.updateAnswers({ ...props.answeredOption, answerChoice: props.answeredChoiceType });
				}}
			>
				<FontIcon
					iconName={
						props.answeredChoiceType === AnswerChoice.no
							? 'Cancel'
							: props.answeredChoiceType === AnswerChoice.yes
							? 'CheckMark'
							: 'Blocked'
					}
				/>
			</div>
		</>
	);
};
