import React from 'react';
import { useState } from 'react';
import styles from './ResultGraphRating.module.scss';
import { IQuestion } from '../../store/business/interfaces';
import { IAnswerOptionResult, IQuestionResult } from '../../store/business/interfaces/IQuestionResult';
import { ResultRatingGeneral } from '../atoms/ResultRatingGeneral';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';

export interface ResultGraphRatingProps {
    questionResult: IQuestionResult;
    question: IQuestion;
}

/**
 * renders question result for each answer option
 * @param props IQuestionResult, IQuestion
 * @returns component ResultRatingGeneral for each answerOption
 */
export const ResultGraphRating = (props: ResultGraphRatingProps) => {
	const survey = useSelector((state: AppState) => state.business.survey);
    const [stars, setStars] = useState<number>(0);
    const [result, setResult] = useState<IAnswerOptionResult[]>([]);

    useEffect(() => {
        if (props.question) setStars(props.question.maxRating);
        if (props.questionResult) setStars(props.questionResult.maxRating);
        return
    }, [props.question, props.questionResult]);

    useEffect(() => {
        if (!props.questionResult) return;

		const clonedResult: IAnswerOptionResult[] = [...props.questionResult.answerOptions];
        if (survey.sortResultsByVotes) {
            clonedResult.sort((r1, r2) => { return r2.averageRating - r1.averageRating; });
        }
		setResult(clonedResult);
    }, [props.questionResult, survey]);

    return (
        <>
            {
                result?.map((ao, index) => {
                    return (
                        <div data-testid={`resultGraphRating${index}`} key={index} className={styles.resultRatingGeneralContainer}>
                            <div>
                                <ResultRatingGeneral
                                    averageRating={ao.averageRating}
                                    question={props.question}
                                    answerOption={ao}
                                    index={index}
                                    maxRating={stars} />
                            </div>
                        </div>)
                })
            }
        </>
    )
}

