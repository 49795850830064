import React, { useEffect, useRef } from 'react';
import styles from './App.module.scss';
import { BrowserRouter } from 'react-router-dom';
import { Hub } from './routes/Hub';
import { AppState, store } from './store';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { Messages } from './shared/components/atoms/Messages';
import { FontIcon } from 'office-ui-fabric-react';
import { useDoubleTap } from './hooks/useDoubleTap';
import { appConfigLoaded, messageAdd, messageRemove, messagesClear } from './store/system/actions';
import { MessageType, startNotification } from './shared/notifications';
import './services/manager/PersonManager';
import './services/manager/RatingManager';


//start notifications
startNotification({
	add: (type: MessageType, message: string, sticky: boolean) => {
		store.dispatch(messageAdd(type, message, sticky));
	},
	clear: () => {
		store.dispatch(messagesClear());
	},
});

export interface Props {
	appConfig: any;
}

const InnerApp = (props: Props) => {
	const refRoot = useRef<HTMLDivElement>();
	const doubleTap = useDoubleTap();

	useEffect(() => {
		refRoot?.current?.addEventListener('touchstart', doubleTap, { passive: false });
	});

	const calcRealHeight = () => {
		let vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	};
	window.addEventListener('resize', () => {
		calcRealHeight();
	});
	calcRealHeight();

	if (props.appConfig.version !== 1) {
		//TODO: i18n
		return (
			<div>
				<h3>Tool veraltet!</h3>
				<FontIcon iconName='Warning' />
				<span>Bitte aktualisieren Sie das Tool.</span>
			</div>
		);
	} else {
		store.dispatch(appConfigLoaded(`${props.appConfig.apiUrl}/api/v1`, props.appConfig.version));
	}

	return (
		<div className={styles.app} ref={refRoot}>
			<BrowserRouter>
				<Hub />
			</BrowserRouter>
		</div>
	);
};

const ReduxedApp = (props: Props) => {
	const dispatch = useDispatch();
	const messages = useSelector((state: AppState) => state.system.messages);
	return(
		<>
			<InnerApp {...props} />
			<Messages messages={messages} onRemoveMessage={(i) => dispatch(messageRemove(i))} />
		</>
	)
}

export const App = (props: Props) => {
	return (
		<Provider store={store}>
			<ReduxedApp {...props} />
		</Provider>
	);
};
