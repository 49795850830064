import { IQuestion, IAnsweredOption } from '../../store/business/interfaces';
import { IManager } from './IManager';

export class DefaultManager implements IManager {

	areYouResponsible(question: IQuestion) {
		return true;
	};

	prepareAnsweredOptions(answeredOptions: IAnsweredOption[]) {
		return answeredOptions;
	}
}