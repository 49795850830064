import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';

import { LoadingSpinnerButton } from '../atoms/LoadingSpinnerButton';
import { PersonAnswer } from '../atoms/PersonAnswer';
import { PersonBlock } from '../atoms/PersonBlock';
import { PersonBlockHeader } from '../atoms/PersonBlockHeader';
import { AnswerChoice, IPersonAnsweredOption, IPersonChoice, IQuestion, ISurvey } from '../../store/business/interfaces';
import { MessageType, SystemLoading } from '../../store/system/enums';
import { BallotCounter } from '../atoms/BallotCounter';
import { PersonHintRow } from '../atoms/PersonHintRow';
import { showMessage } from '../../shared/notifications';

import styles from './VotingForm.module.scss';
import personStyles from './PersonForm.module.scss';

export interface Props {
	question: IQuestion;
	survey: ISurvey;
	onSend: (answeredOptions: IPersonAnsweredOption[][]) => void;
}

export const PersonForm = (props: Props) => {
	const { t } = useTranslation();

	const [answeredOptions, setAnsweredOptions] = useState<IPersonAnsweredOption[][]>([]);
	const [, setCurrentQuestion] = useState<IQuestion>();
	const [currentBallot, setCurrentBallot] = useState<number>(0);
	const [remainingYes, setRemainingYes] = useState(props.question.votesPerMember);

	const prevBallotContainerRef = React.useRef(null);
	const nextBallotContainerRef = React.useRef(null);

	const hasUnvotedAnswerOptions = !!answeredOptions[currentBallot]?.some((ao) => ao.answerChoice === undefined);

	const isUnlimited = props.question.votesPerMember === props.question.answerOptions.length;

	useEffect(() => {
		const ballots: IPersonAnsweredOption[][] = [];
		const ballotCount = props.question.allowVotesSplitting ? props.question.userVoteWeight : 1;
		for (let i = 0; i < ballotCount; i++) {
			if (props.question.answeredOptions.length > 0) {
				const ballotAnswerOptions = (props.question.answeredOptions as IPersonAnsweredOption[]).filter(
					(ao: IPersonAnsweredOption) => ao.ballotNumber === i + 1
				);
				ballots[i] = ballotAnswerOptions;
			} else {
				const ballot = props.question.answerOptions.map((ao) => {
					return {
						id: ao.id,
						voteWeight: props.question.allowVotesSplitting ? 1 : props.question.userVoteWeight,
						ballotNumber: i + 1,
					};
				});
				ballots[i] = ballot;
			}
		}
		setCurrentQuestion((oldQuestion) => {
			if (oldQuestion?.id !== props.question?.id) {
				setCurrentBallot(0);
				return props.question;
			}
			return oldQuestion;
		});
		setAnsweredOptions(ballots);
	}, [props.question]);

	useEffect(() => {
		if (!answeredOptions[currentBallot]) return;
		const selectedYes = answeredOptions[currentBallot].filter((ao) => ao.answerChoice === AnswerChoice.yes).length;
		setRemainingYes(props.question.votesPerMember - selectedYes);
	}, [answeredOptions, currentBallot, props.question.votesPerMember]);

	const setBlockAnswer = useCallback(
		(blockAnswer: IPersonChoice) => {
			setAnsweredOptions((oldAnsweredOptions) => {
				const updatedAnswerOptions = [...oldAnsweredOptions];
				updatedAnswerOptions[currentBallot].forEach((ao) => (ao.answerChoice = blockAnswer.answerChoice));
				return updatedAnswerOptions;
			});
		},
		[currentBallot]
	);

	const getBlockAnswer = useCallback((): IPersonChoice => {
		const result: IPersonChoice = {
			answerChoice: undefined,
		};
		const currentAnswerOptions = answeredOptions[currentBallot];
		if (currentAnswerOptions) {
			const firstAnswerChoice = currentAnswerOptions[0]?.answerChoice;
			result.answerChoice = currentAnswerOptions.every((ao) => ao.answerChoice === firstAnswerChoice)
				? firstAnswerChoice
				: undefined;
		}
		return result;
	}, [answeredOptions, currentBallot]);

	const updateAnswers = (chosenAnswer: IPersonAnsweredOption) => {
		const updatedAnswerOptions = [...answeredOptions];
		updatedAnswerOptions[currentBallot] = updatedAnswerOptions[currentBallot]
			.filter((ao) => ao.id !== chosenAnswer.id)
			.concat(chosenAnswer);

		setAnsweredOptions(updatedAnswerOptions);
	};

	const reusePreviousSelection = () => {
		const previousIndex = currentBallot - 1;
		if (previousIndex === -1) return;

		const updatedAnswerOptions = [...answeredOptions];
		const currentAnswerOptions = updatedAnswerOptions[currentBallot];

		updatedAnswerOptions[currentBallot] = updatedAnswerOptions[previousIndex].map((uao) => {
			const cao = currentAnswerOptions.find((cao) => cao.id === uao.id);
			return { ...cao, answerChoice: uao.answerChoice };
		});

		setAnsweredOptions(updatedAnswerOptions);
	};

	return (
		<>
			{(props.question.userVoteWeight > 1) ? (
				<>
				{(props.question.allowVotesSplitting) ? (
					<BallotCounter
						question={props.question}
						setCurrent={setCurrentBallot}
						currentBallot={currentBallot}
						disabled={
							answeredOptions[currentBallot] && answeredOptions[currentBallot].some((ao) => ao.answerChoice === undefined)
						}
						disabledMessage={t('messages.personVoteAllPersons')}
					/>
				) : (
					''
				)}
				</>
			) : (
				''
			)}

			<PersonHintRow question={props.question} remainingYes={remainingYes} />

			{props.question.userVoteWeight > 1 && props.question.allowVotesSplitting && (
				<CSSTransition
					nodeRef={prevBallotContainerRef}
					in={currentBallot > 0}
					timeout={150}
					unmountOnExit
					classNames={{
						enter: personStyles.ballotContainerEnter,
						enterActive: personStyles.ballotContainerEnterActive,
						enterDone: personStyles.ballotContainerEnterDone,
						exit: personStyles.ballotContainerExit,
						exitActive: personStyles.ballotContainerExitActive,
						exitDone: personStyles.ballotContainerExitDone,
					}}
				>
					<div ref={prevBallotContainerRef} className={personStyles.nextBallotContainer}>
						<button onClick={reusePreviousSelection} disabled={props.question.hasAnswered}>
							{t('form.reUsePreviousSelection')}
						</button>
					</div>
				</CSSTransition>
			)}

			{isUnlimited && (
				<PersonBlock
					answeredOption={getBlockAnswer()}
					disabled={props.question.hasAnswered}
					updateAnswers={setBlockAnswer}
				/>
			)}

			<PersonBlockHeader label={isUnlimited && t('form.individualVote')} withLegend={!isUnlimited} />
			<div className={personStyles.personAnswerContainer}>
				{props.question.answerOptions.map((o, i) => {
					return (
						<PersonAnswer
							key={`ao_${o.id}`}
							questionText={o.title}
							answeredOption={
								answeredOptions[currentBallot] && answeredOptions[currentBallot].find((ao) => ao.id === o.id)
							}
							disabled={props.question.hasAnswered}
							updateAnswers={updateAnswers}
							blockYes={remainingYes === 0}
						/>
					);
				})}
			</div>

			{props.question.userVoteWeight > 1 && props.question.allowVotesSplitting && (
				<CSSTransition
					nodeRef={nextBallotContainerRef}
					in={currentBallot < answeredOptions.length - 1}
					timeout={150}
					unmountOnExit
					classNames={{
						enter: personStyles.ballotContainerEnter,
						enterActive: personStyles.ballotContainerEnterActive,
						enterDone: personStyles.ballotContainerEnterDone,
						exit: personStyles.ballotContainerExit,
						exitActive: personStyles.ballotContainerExitActive,
						exitDone: personStyles.ballotContainerExitDone,
					}}
				>
					<div ref={nextBallotContainerRef} className={personStyles.nextBallotContainer}>
						<button
							className={hasUnvotedAnswerOptions && personStyles.disabled}
							onClick={() => {
								if (hasUnvotedAnswerOptions) {
									showMessage('messages.personVoteAllPersons', MessageType.WARN);
								} else {
									currentBallot < props.question.userVoteWeight - 1 && setCurrentBallot(currentBallot + 1);
								}
							}}
						>
							{t('form.nextBallot')}
						</button>
					</div>
				</CSSTransition>
			)}

			<div
				onClick={() => {
					if (answeredOptions.some((ao) => ao.some((a) => a.answerChoice === undefined))) {
						showMessage('messages.fillOutAllBallots', MessageType.WARN);
					}
				}}
			>
				<LoadingSpinnerButton
					className={styles.button}
					loadingClassName={styles.loadingButton}
					text={t('form.vote')}
					onClick={() => answeredOptions && props.onSend(answeredOptions)}
					disabled={
						answeredOptions.some((ao) => ao.some((a) => a.answerChoice === undefined)) || props.question.hasAnswered
					}
					exclude={[SystemLoading.Login, SystemLoading.Config]}
				/>
			</div>
		</>
	);
};
