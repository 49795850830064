import React, { useState } from 'react';
import { TextField } from 'office-ui-fabric-react';
import styles from './LoginCode.module.scss';
import { LoadingSpinnerButton } from '../components/atoms/LoadingSpinnerButton';
import { useTranslation } from 'react-i18next';

export interface Props {
	onNext: (code: string) => void;
	label: string;
	placeholder: string;
	type: 'surveyCode' | 'pin' | 'name';
	maxLength?: number;
	validationErrorVisible?: boolean;
	validationErrorMessage: string;
}

export const LoginCode = (props: Props) => {
	const [loginCode, SetLoginCode] = useState('');
	const { t } = useTranslation();

	return (
		<div className={[styles.loginContainer, props.type !== 'surveyCode' ? styles.specialContainer : ''].join(' ')}>
			<TextField
				autoFocus
				label={props.label}
				placeholder={props.placeholder}
				defaultValue={loginCode}
				maxLength={props.maxLength}
				autoComplete='off'
				onChange={(evt, value) => {
					SetLoginCode(value ?? '');
				}}
				onKeyUp={(evt) => {
					if (evt.key === 'Enter') {
						props.onNext(loginCode);
					}
				}}
				className={props.validationErrorVisible ? styles.error : ''}
				type={props.type === 'pin' ? 'number' : 'text'}
				errorMessage={props.validationErrorVisible ? props.validationErrorMessage : ''}
			/>
			<LoadingSpinnerButton
				loadingClassName={styles.loadingButton}
				text={t('button.continue')}
				onClick={() => {
					props.onNext(loginCode);
				}}
				disabled={loginCode.length === 0}
			/>
		</div>
	);
};
