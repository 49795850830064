import { store } from '../store';
import { xhr } from '../xhr';
import { systemLoading } from '../store/system/actions';
import { SystemLoading } from '../store/system/enums';
import { surveyLoaded, businessLoading, memberLoaded, designLoaded } from '../store/business/actions';
import { BusinessDataType } from '../store/business/enums';
import { SessionService } from './SessionService';
import { QuestionService } from './QuestionService';
import { DesignService } from './DesignService';

export class VotingService {
	public static async ensureSurvey(forceReload = false) {
		const { business } = store.getState();
		if ((business.loading[BusinessDataType.Survey] || business.survey) && !forceReload) {
			return;
		}
		store.dispatch(businessLoading(BusinessDataType.Survey, true));
		try {
			const response = await xhr(`Voting/Survey`, {
				method: 'GET',
				onError: QuestionService.onError,
			});
			if (response.data['title']) {
				store.dispatch(
					surveyLoaded({
						title: response.data['title'],
						useVoteWeightInPercent: response.data['useVoteWeightInPercent'],
						useQuickVotes: response.data['useQuickVotes'],
						sortResultsByVotes: response.data['sortResultsByVotes'],
						disableResultsInMemberView: response.data['disableResultsInMemberView'],
					})
				);
			}
			const design = await DesignService.getDesign('_');
			store.dispatch(designLoaded(design));
		} finally {
			store.dispatch(businessLoading(BusinessDataType.Survey, false));
		}
	}

	public static async logout() {
		const { system } = store.getState();
		if (system.loading[SystemLoading.Login]) {
			return;
		}
		store.dispatch(systemLoading(SystemLoading.Login, true));
		try {
			await xhr(`Member/Logout`, {
				method: 'GET',
			});
		} catch (ex) {
			//in case of 401, but can be ignored
		} finally {
			await SessionService.deleteSession();
			store.dispatch(systemLoading(SystemLoading.Login, false));
		}
	}

	public static async login(surveyCode: string, memberLogin?: string) {
		const { system } = store.getState();
		const { business } = store.getState();
		if (system.loading[SystemLoading.Login]) {
			return;
		}
		store.dispatch(systemLoading(SystemLoading.Login, true));
		try {
			const response = await xhr(`Member/Login`, {
				method: 'POST',
				data: { surveyCode, memberLogin },
			});
			if (response.data.survey) {
				store.dispatch(
					surveyLoaded({
						title: response.data.survey['title'],
						useVoteWeightInPercent: response.data.survey['useVoteWeightInPercent'],
						useQuickVotes: response.data.survey['useQuickVotes'],
						sortResultsByVotes: response.data.survey['sortResultsByVotes'],
						disableResultsInMemberView: response.data.survey['disableResultsInMemberView'],
					})
				);
				SessionService.createSession(business.tenantId, '', '', '');
			}
			if (response.data.member) {
				const validMemberLogin = response.data.member['login'];
				const validSurveyCode = response.data.member['surveyCode'];
				const validConnection = response.data.member['connection'];
				store.dispatch(memberLoaded(business.tenantId, validMemberLogin, validSurveyCode, validConnection));
				SessionService.createSession(business.tenantId, validMemberLogin, validSurveyCode, validConnection);
			}
		} finally {
			store.dispatch(systemLoading(SystemLoading.Login, false));
		}
	}
}
