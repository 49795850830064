import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import './i18n/index';
import { App } from './App';
import * as serviceWorker from './serviceWorker';
import { initializeIcons } from '@uifabric/icons';
import { SystemService } from './services/SystemService';

initializeIcons();

SystemService.getAppConfig().then((appConfig) => {
	ReactDOM.render(
		<React.StrictMode>
			<App appConfig={appConfig} />
		</React.StrictMode>,
		document.getElementById('root')
	);
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();